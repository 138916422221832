<template>
    <ProtoNavigationBar
        theme="accadisHSTheme"
        image="/img/icon-socialmedia.png"
    >
        <template #title>
            <span class="font-weight-bold">#weareaccadis</span>
        </template>
    </ProtoNavigationBar>
</template>
